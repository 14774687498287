export const colors = {
  lightText: '#464C54',
  darkText: '#CDCDD1',
  darkIcon: '#619CF6',
  lightIcon: '#1F62E0',
  darkDescription: '#C7D0D9',
  lightDescription: '#484D54',
};

export const PANEL_BREAKPOINT = 630;
