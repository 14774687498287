import React, { FC } from 'react';
import { css } from 'emotion';

import { GrafanaTheme } from '@grafana/data';
import { stylesFactory, useStyles } from '@grafana/ui';
import { colors } from '../../utils';

export interface CardButtonProps {
  description: string;
  href: string;
  logo: string;
  title: string;
}

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    card: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      max-height: 100px;
      background: ${theme.colors.bg2};
      padding: 16px;
      border-radius: 2px;
      margin-bottom: 12px;
      &:hover {
        background: ${theme.colors.bg3};
      }
    `,
    description: css`
      color: ${theme.isLight ? colors.lightDescription : colors.darkDescription};
      font-size: ${theme.typography.size.sm};
      line-height: 1.5rem;
      font-weight: lighter;
      margin-bottom: 0;
      margin-top: ${theme.typography.size.sm};
      width: 100%;
      @media (max-width: 1500px) {
        line-height: 1rem;
      }
      @media (max-width: 1340px) {
        display: none;
      }
    `,
    header: css`
      color: ${theme.isLight ? colors.lightText : colors.darkText};
      font-size: 16px;
      font-weight: lighter;
      margin-bottom: 0;
      line-height: 22px;
      @media (max-width: 1280px) {
        font-size: 12px;
      }
    `,
    headerWrapper: css`
      @media (max-width: 1120px) {
        display: none;
      }
    `,
    logoWrapper: css`
      display: flex;
      height: 40px;
      justify-content: center;
      margin-right: 10px;
      width: 40px;

      img {
        align-self: center;
        max-height: 100%;
        max-width: 100%;
      }
    `,
    titleWrapper: css`
      display: flex;
      align-items: center;
    `,
  };
});

export const CardButton: FC<CardButtonProps> = ({ href, logo, title, description }) => {
  const styles = useStyles(getStyles);
  const imgUrl = `https://storage.googleapis.com/grafanalabs-integration-logos/${logo}`;
  return (
    <a className={styles.card} href={href}>
      <div className={styles.titleWrapper}>
        <div className={styles.logoWrapper}>
          <img src={imgUrl} alt={title} />
        </div>
        <div className={styles.headerWrapper}>
          <div className={styles.header}> {title}</div>
        </div>
      </div>
      <p className={styles.description}>{description}</p>
    </a>
  );
};
