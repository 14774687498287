import { css, cx } from 'emotion';
import React from 'react';

import { PanelProps } from '@grafana/data';

import { SimpleOptions } from 'types';
import { CardButton } from '../CardButton';

interface Props extends PanelProps<SimpleOptions> {}

const integrations = [
  {
    description: 'Monitor a Kubernetes cluster',
    id: 'kubernetes',
    img: 'kubernetes.svg',
    title: 'Kubernetes',
    href: 'a/grafana-k8s-app/cluster',
  },
  {
    description: 'Monitor any Linux machine',
    id: 'linux-node',
    img: 'linux.png',
    title: 'Linux Server',
  },
  {
    description: 'Monitor Docker containers',
    id: 'docker',
    img: 'docker.png',
    title: 'Docker',
  },
  {
    description: 'Monitor a MySQL database',
    id: 'mysql',
    img: 'mysql.png',
    title: 'MySQL',
  },
  {
    description: 'Monitor a PostgreSQL database',
    id: 'postgres',
    img: 'postgres.svg',
    title: 'PostgreSQL',
  },
  {
    description: 'Monitor a Windows instance',
    id: 'windows-exporter',
    img: 'windows.png',
    title: 'Windows Exporter',
  },
];

const getStyles = () => {
  return {
    wrapper: css`
      padding: 8px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `,
    integrationsList: css`
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    `,
    item: css`
      display: flex;
      flex: 1 1 26%;
      margin: 2px 8px;
    `,
    link: css`
      text-decoration: none;
    `,
  };
};

export const CloudHomeIntegrations: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();

  return (
    <div
      className={cx(
        'integrations-panel',
        styles.wrapper,
        css`
          height: ${height}px;
          width: ${width}px;
        `
      )}
    >
      <p>
        Get preconfigured dashoards and alerts, in a few minutes, by installing one of our{' '}
        <a className={styles.link} href="/a/grafana-easystart-app">
          <u>integrations</u>
        </a>
        .
      </p>
      <ul className={styles.integrationsList}>
        {integrations.map(({ title, id, img, description, href }) => (
          <li className={styles.item} key={title}>
            <CardButton
              description={description}
              href={href ?? `/a/grafana-easystart-app/${id}`}
              logo={img}
              title={title}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
